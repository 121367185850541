<template>
  <div class="columns is-multiline">
    <div class="column is-3" />
    <div class="column is-6">
      <h3 class="has-text-weight-bold is-size-2">Reset Password</h3>
      <div class="is-size-5 asphalt-grey pb-4">
        Your identity has been verified. Set your new password.
      </div>
      <validation-observer ref="setPassword" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(createPass)">
          <div class="columns is-multiline">
            <div class="column is-12">
              <validation-provider
                name="password"
                rules="required|upCase|number|minPass:8"
                v-slot="{ errors }"
                immediate
                :bails="false"
              >
                <b-field label="Password" class="password-tooltip">
                  <b-tooltip
                    :triggers="['click']"
                    :auto-close="['outside', 'escape']"
                    type="is-dark"
                    expanded
                    multilined
                  >
                    <template v-slot:content>
                      <div class="password-tooltip-content">
                        Please make sure that your password includes :
                        <p v-for="(message, m) in errorRules(errors)" :key="m">
                          <span v-if="message.isValid">
                            <img :src="urlValid" />
                          </span>
                          <span v-else>
                            <img :src="urlInvalid" />
                          </span>
                          {{ message.text }}
                        </p>
                      </div>
                    </template>
                    <b-input
                      v-model="password"
                      expanded
                      password-reveal
                      placeholder="Password"
                      type="password"
                      validate-vv-on="focus"
                      @input="errorRules"
                    />
                  </b-tooltip>
                </b-field>
              </validation-provider>
            </div>
            <div class="column is-12">
              <validation-provider
                name="confirm password"
                rules="required|confirmed:password"
                v-slot="{ errors }"
              >
                <b-field
                  label="Re-enter Password"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    v-model="confirmPass"
                    expanded
                    password-reveal
                    placeholder="Password"
                    type="password"
                  />
                </b-field>
              </validation-provider>
            </div>
            <div class="column is-12">
              <b-button
                expanded
                @click.native="createPass"
                :loading="isLoading"
                :disabled="isLoading"
              >
                Create password
              </b-button>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
    <div class="column is-3" />
  </div>
</template>

<script>
import ApiService from '@/services/common/api.service'
import { showToast } from '@/services/util'
export default {
  data() {
    return {
      password: '',
      confirmPass: '',
      isLoading: false,
      token: null,
      email: null,
      tooltipMessages: [
        'Lowercase and uppercase letters',
        'at least one number',
        '8 or more characters',
      ],
      urlValid: require('@/assets/images/CreatePassword/green-checkmark.svg'),
      urlInvalid: require('@/assets/images/CreatePassword/error-icon.svg'),
    }
  },

  mounted() {
    this.queryParams()
  },

  computed: {
    isError() {
      return this.password.length > 0
    },
  },

  methods: {
    async createPass() {
      this.isLoading = true
      let form = {
        token: this.token,
        password: this.password,
        email: this.email,
        type: 'forgot',
      }

      await ApiService.put('/reset-password', form, true)
        .then(() => {
          showToast('Password set successfully!', 'is-success', 'is-top')
          this.$router.push('login')
          this.isLoading = false
        })
        .catch((e) => {
          showToast(e.response.message, 'is-danger', 'is-top')
          this.isLoading = false
        })
    },

    queryParams() {
      this.token = this.$route.query.token
      this.email = this.$route.query.email
      if (!this.token || !this.email) {
        this.$router.push('login')
      }
    },

    errorRules(e) {
      console.log('errs', e)
      return this.tooltipMessages.map((message) => {
        let isValid = !e.includes(message)
        return {
          isValid,
          text: message,
        }
      })
    },
  },
}
</script>
